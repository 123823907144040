<template>
	<div>
		<navComponents />
		<div class="bg1" :style="{ width: screenWidth }">
			<div class="w">
				<p>
					StaRiver RDP，目标成为新一代的数据基础设施平台提供商，聚集多数据领域的实时全栈链路的构建和准备，让数据随需随得，助力企业实现快速业务创新。
				</p>
			</div>
		</div>
		<div class="bg2" :style="{ width: screenWidth }">
			<div class="w">
				<div class="bg2-text-box">
					<div class="p1">
						ABOUT US
						<div class="p1-zh">关于我们</div>
					</div>
					<p class="p2">
						StaRiver RDP
						实时数据融合产品致力于为用户提供企业级数据融合解决方案，为用户提供统一的平台，同时管理多源异构数据节点的实时同步与批量数据处理任务，采用分布式集群化部署方式，可水平垂直线性扩展，保证数据流转稳定高效，帮助企业快速消除因为“烟囱式”、“项目制”等情况而导致的数据孤岛现状，助力企业的数据业务创新，专注数据价值释放。
					</p>
					<p class="p3">
						StaRiver RDP 实时数据融合产品通过团队多年在数据融合技术领域的积累，支持 Oracle、MySQL、 Microsoft SQLServer、PostgreSQL
						以及国内主流数据库的实时增量数据捕获，基于异构语义映射实现异构数据实时融合，
						帮助企业提升数据流转时效性，降低异构数据融合成本。在支持传统关系型数据库的基础上，对大数据平台、国产数据库、云原生数据库、API
						及对象存储也提供广泛的支持，并在不断扩展。
					</p>
					<p class="p3">
						StaRiver
						核心团队成员均拥有多年在数据融合技术领域的创新经验和实践积累，来自于国内外知名企业和学院机构。公司现已服务了政府、金融、医疗等在各自行业信息化水平领先的标杆客户，广受客户认可。产品在稳定性、可靠性、可管理性、扩展性、灵活性等方面经过了多种应用场景的反复验证，在系统性能指标、架构设计的专业性与技术先进性上均得到了客户的好评。
					</p>
					<p class="p4">
						StaRiver 将坚持“开放+生态”战略，加速Real Time DaaS
						架构的普及，除了持续提升数据融合平台的硬实力和优化技术服务的软实力，还将与用户、合作伙伴携手共建应用生态、推动产业进步。
					</p>
				</div>
				<div class="center" v-if="!isMobile"></div>
				<div class="bg2-small-img" v-if="!isMobile">
					<img src="~@/assets/images/aboutus-small.png" alt="" />
				</div>
			</div>
		</div>
		<div class="bg3" :style="{ width: screenWidth }" v-if="!isMobile">
			<div class="w">
				<div class="bg3-title">
					<p class="p1">「联系我们」</p>
					<div class="hengxian"></div>
				</div>
				<div class="bg3-4box">
					<div class="bg4-small-box">
						<div class="bg4-small-box-icon1">
							<img src="~@/assets/images/wechat-code.png" alt="" />
						</div>
						<p class="p3">扫描二维码，了解更多</p>
						<!-- <p class="p4">StaRiver RDP实时数据融合服务平台</p> -->
						<div class="icon-smoke"></div>
					</div>
					<div class="bg4-small-box">
						<div class="bg4-small-box-icon2">
							<img src="~@/assets/images/aboutus-bg3-email.png" alt="" />
						</div>
						<p class="p3">EMAIL</p>
						<p class="p4">markting@istariver.com</p>
						<div class="icon-smoke"></div>
					</div>
					<div class="bg4-small-box">
						<div class="bg4-small-box-icon3">
							<img src="~@/assets/images/aboutus-bg3-home.png" alt="" />
						</div>
						<p class="p3">WEB</p>
						<p class="p4">www.istariver.com</p>
						<div class="icon-smoke"></div>
					</div>
					<!-- <div class="bg4-small-box">
						<div class="bg4-small-box-icon4">
							<img src="~@/assets/images/aboutus-bg3-dizhi.png" alt="" />
						</div>
						<p class="p3">ADD</p>
						<p class="p4">苏州工业园区金鸡湖大道1355号<br />国际科技园</p>
						<div class="icon-smoke"></div>
					</div> -->
				</div>
			</div>
		</div>
		<!-- <div class="bg4" :style="{ width: screenWidth }">
			<div class="bg4-text">苏州</div>
			<div class="bg4-yuandian-border">
				<div class="bg4-yuandian"></div>
			</div>
			<div class="bg4-text2">
				<p class="p1">星河数聚科技（苏州）有限公司</p>
				<p class="p2">苏州工业园区金鸡湖大道1355号国际科技园</p>
			</div>
		</div> -->
		<CopyrightComponents />
	</div>
</template>

<script>
import navComponents from "@/components/Nav/Nav.vue";
import CopyrightComponents from "@/components/Copyright/Copyright.vue";
export default {
	components: { navComponents, CopyrightComponents },
	props: {},
	data() {
		return {
			screenWidth: "",
			screenHeight: "",
		};
	},
	computed: {
		isMobile() {
			return (
				navigator.userAgent.match(
					/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
				) || this.screenWidth < 900
			);
		},
	},
	watch: {
		screenWidth() {
			console.log(this.screenWidth); //浏览器窗口变化时，打印宽度。
		},
	},
	created() {},
	mounted() {
		this.screenWidth = document.body.clientWidth; //监听页面缩放
		this.screenHeight = document.body.clientHeight;
		window.onresize = () => {
			return (() => {
				this.screenWidth = document.body.clientWidth;
				this.screenHeight = document.body.clientHeight;
			})();
		};
	},
	methods: {},
};
</script>

<style scoped lang="scss">
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
.bg1,
.bg2,
.bg3,
.bg4,
.bg5 {
	width: 100%;
}
.bg1 {
	height: 550px;
	background: url("~@/assets/images/aboutus-bg1.png") no-repeat center;
	background-size: 100% 100%;
	color: #fff;
	margin: 0 auto;
	display: flex;
	align-items: center;
	.w {
		width: 1040px;
		margin: 0 auto;
		p {
			height: 78px;
			font-size: 24px;
			font-family: "DFPLiJinHeiW8-GB";
			font-weight: 600;
			color: #ffffff;
			line-height: 42px;
			text-align: center;
		}
	}
}
.bg2 {
	height: 550px;
	background: url("~@/assets/images/aboutus-bg2.png") no-repeat center center;
	background-size: 100% 100%;
	margin: 0 auto;
	.w {
		width: var(--container-width);
		height: 100%;
		margin: 0 auto;
		border: 1px solid transparent;
		display: flex;
		justify-content: space-between;
		align-items: center;
		.bg2-text-box {
			flex: 4;
			.p1 {
				font-size: 30px;
				font-family: "Microsoft YaHei";
				font-weight: bold;
				color: #d1d1d1;
				position: relative;
				margin-bottom: 35px;
				.p1-zh {
					top: -7px;
					left: 0;
					font-size: 22px;
					font-family: "Microsoft YaHei";
					font-weight: 700;
					color: #000000;
					position: absolute;
				}
			}
			.p2,
			.p3,
			.p4 {
				font-size: 14px;
				color: #000000;
				line-height: 25.6px;
				text-indent: 28px;
				text-align: justify;
			}
			.p2,
			.p3 {
				margin-bottom: 10px;
			}
		}

		.bg2-small-img {
			flex: 3;
			height: 100%;
			margin-left: 50px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			img {
				width: 100%;
			}
		}
	}
}
.bg3 {
	height: 410px;
	margin: 0 auto;
	.w {
		width: var(--container-width);
		margin: 0 auto;
		// overflow: hidden;
		.bg3-title {
			width: 300px;
			margin: 70px auto 60px;
			.p1 {
				font-size: 20px;
				font-family: "Microsoft YaHei";
				font-weight: 700;
				color: #1b1b1b;
				margin-bottom: 35px;
				text-align: center;
			}
			.p2 {
				font-size: 14px;
				font-family: "Microsoft YaHei";
				font-weight: 500;
				color: #000000;
				margin-bottom: 30px;
				text-align: center;
			}
			.hengxian {
				width: 60px;
				height: 2px;
				margin: 0 auto;
				background-color: #000;
			}
		}
		.bg3-4box {
			height: 275px;
			display: flex;
			justify-content: space-between;
			.bg4-small-box {
				flex: 1;
				position: relative;
				display: flex;
				flex-direction: column;
				align-items: center;
				.bg4-small-box-icon1,
				.bg4-small-box-icon2,
				.bg4-small-box-icon3,
				.bg4-small-box-icon4 {
					width: 130px;
					height: 130px;
					border-radius: 50%;
					overflow: hidden;
					img {
						width: 100%;
						height: 100%;
					}
				}

				.bg4-small-box-icon1 {
					display: flex;
					justify-content: center;
					align-items: center;
					background-color: #fff;
					border: 1px solid #b6b6b6;
					img {
						width: 45%;
						height: 45%;
					}
				}
				.p3 {
					position: absolute;
					font-size: 16px;
					font-family: "Microsoft YaHei";
					font-weight: 500;
					color: #000000;
					text-align: center;
					bottom: 84px;
					transform: translateX(-50%);
					left: 50%;
				}
				.p4 {
					position: absolute;
					font-size: 14px;
					font-family: "Microsoft YaHei";
					font-weight: 500;
					color: #000000;
					text-align: center;
					top: 200px;
					transform: translateX(-50%);
					z-index: 888;
					left: 50%;
				}
				.icon-smoke {
					position: absolute;
					width: 254px;
					height: 252px;
					top: -20px;
					left: 133px;
					background: url("~@/assets/images/aboutus-icon-smoke.png") no-repeat center;
					background-size: 73%;
					z-index: -1;
				}
			}
		}
	}
}
.bg4 {
	height: 275px;
	background: url("~@/assets/images/aboutus-bg4.png") no-repeat center;
	background-size: 100% 100%;
	margin: 0 auto;
	position: relative;

	.bg4-text,
	.bg4-yuandian-border,
	.bg4-text2 {
		position: absolute;
	}
	.bg4-text {
		right: 346px;
		top: 94px;
		font-size: 18px;
		font-family: "Microsoft YaHei";
		font-weight: 700;
		color: #297aff;
	}
	.bg4-yuandian-border {
		right: 354px;
		top: 124px;
		width: 22px;
		height: 22px;
		border-radius: 50%;
		border: 1px solid #297aff;
		.bg4-yuandian {
			width: 12px;
			height: 12px;
			border-radius: 50%;
			background-color: #297aff;
			margin: 4px auto;
		}
	}
	.bg4-text2 {
		background-color: #297aff;
		top: 95px;
		right: 392px;
		font-size: 14px;
		padding: 15px 25px;
		border-radius: 10px;
		color: #fff;
		.p1 {
			margin-bottom: 7px;
		}
	}
}

@media (max-width: 900px) {
	.bg1 {
		background-position: 73% 100%;
		background-size: auto;
		height: 375px;
		.w {
			width: 85%;
			P {
				font-size: 16px;
				color: #ffffff;
				line-height: 30px;
			}
		}
	}
	.bg2 {
		height: 100%;
		.w {
			.bg2-text-box {
				padding: 10px;
				.p1 {
					margin-top: 20px;
					margin-bottom: 15px;
				}
			}
		}
	}
}
</style>
